import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Card = styled.div`
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: transform 0.2s;
  width: 300px;
  height: 350px;
  margin: 0 auto;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.15);
  }
`;

const GameImage = styled.img`
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
`;

const GameTitle = styled.h3`
  margin: 0.5rem 0;
  color: #333;
  font-size: 1.5rem;
`;

const GameDescription = styled.p`
  color: #666;
  font-size: 1.1rem;
  line-height: 1.4;
`;

function GameCard({ title, description, image, path }) {
    const navigate = useNavigate();

    return (
        <Card onClick={() => navigate(path)}>
            <GameImage src={image} alt={title} />
            <GameTitle>{title}</GameTitle>
            <GameDescription>{description}</GameDescription>
        </Card>
    );
}

export default GameCard; 