import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import GameCard from './components/GameCard/GameCard.js';
import MathMatch from './components/Games/MathMatch/MathMatch';
import GameBoard from './components/Games/MathMatch/GameBoard';

const AppContainer = styled.div`
  text-align: center;
  min-height: 100vh;
  background: url('/images/space-bg.jpg') no-repeat center center fixed;
  background-size: cover;
  color: white;
`;

const Header = styled.header`
  background-color: rgba(76, 175, 80, 0.9);  // Made slightly transparent
  padding: 1rem;
  color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
`;

const MainContent = styled.main`
  padding: 2rem;
`;

const GameGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2rem;
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
  justify-items: center;
`;

function App() {
  const games = [
    {
      title: "Math Match",
      description: "Practice math skills with a fun matching game!",
      image: "/images/math-match.jpg",
      path: "/math-match"
    },
    {
      title: "Word Scramble",
      description: "Unscramble letters to find hidden words!",
      image: "/images/word-scramble.jpg",
      path: "/word-scramble"
    }
  ];

  return (
    <Router>
      <AppContainer>
        <Header>
          <h1>Fun Learning Games</h1>
        </Header>
        <MainContent>
          <Routes>
            <Route path="/" element={
              <GameGrid>
                {games.map((game, index) => (
                  <GameCard
                    key={index}
                    title={game.title}
                    description={game.description}
                    image={game.image}
                    path={game.path}
                  />
                ))}
              </GameGrid>
            } />
            <Route path="/math-match" element={<MathMatch />} />
            <Route path="/math-match/:difficulty" element={<GameBoard />} />
          </Routes>
        </MainContent>
      </AppContainer>
    </Router>
  );
}

export default App; 