import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

const BoardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  width: 95vw;  // 95% of viewport width
  height: 80vh; // 80% of viewport height
  margin: 2rem auto;
  padding: 1rem;
`;

const CardContainer = styled.div`
  perspective: 1000px;
  height: 100%;  // Fill available height
  width: 100%;   // Fill available width
  min-height: 200px; // Minimum height for smaller screens
`;

const Card = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transform-style: preserve-3d;
  transition: transform 0.3s;
  transform: ${props => props.isFlipped ? 'rotateY(180deg)' : 'none'};
`;

const CardFace = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(1.5rem + 1vw);  // Responsive font size
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
`;

const CardFront = styled(CardFace)`
  background-color: #4CAF50;
  color: white;
`;

const CardBack = styled(CardFace)`
  background-color: hsla(245, 44.8%, 46.9%, 0.8);
  color: white;  // Adding this to ensure text is visible against the darker background
  transform: rotateY(180deg);
`;

// Rest of your problems object stays the same
const problems = {
  easy: [
    { equation: '3 × 4', solution: '12' },
    { equation: '2 × 5', solution: '10' },
    { equation: '3 × 3', solution: '9' },
    { equation: '4 × 2', solution: '8' },
  ],
  medium: [
    { equation: '6 × 7', solution: '42' },
    { equation: '8 × 5', solution: '40' },
    { equation: '4 × 9', solution: '36' },
    { equation: '7 × 4', solution: '28' },
  ],
  hard: [
    { equation: '12 × 8', solution: '96' },
    { equation: '11 × 7', solution: '77' },
    { equation: '9 × 9', solution: '81' },
    { equation: '8 × 8', solution: '64' },
  ]
};

function GameBoard() {
  const { difficulty } = useParams();
  const [cards, setCards] = useState([]);
  const [flippedCards, setFlippedCards] = useState([]);
  const [matchedPairs, setMatchedPairs] = useState([]);

  useEffect(() => {
    const currentProblems = problems[difficulty];
    const gameCards = currentProblems.flatMap(problem => [
      { id: `eq-${problem.equation}`, content: problem.equation, pair: problem.solution },
      { id: `sol-${problem.solution}`, content: problem.solution, pair: problem.equation }
    ]);

    for (let i = gameCards.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [gameCards[i], gameCards[j]] = [gameCards[j], gameCards[i]];
    }

    setCards(gameCards);
  }, [difficulty]);

  const handleCardClick = (cardId) => {
    if (flippedCards.length === 2) return;
    if (flippedCards.includes(cardId)) return;
    if (matchedPairs.includes(cardId)) return;

    const newFlippedCards = [...flippedCards, cardId];
    setFlippedCards(newFlippedCards);

    if (newFlippedCards.length === 2) {
      const [firstCard, secondCard] = newFlippedCards.map(id =>
        cards.find(card => card.id === id)
      );

      if (firstCard.pair === secondCard.content) {
        setMatchedPairs([...matchedPairs, ...newFlippedCards]);
        setFlippedCards([]);
      } else {
        setTimeout(() => {
          setFlippedCards([]);
        }, 1500);
      }
    }
  };

  return (
    <BoardContainer>
      {cards.map(card => (
        <CardContainer key={card.id}>
          <Card
            isFlipped={flippedCards.includes(card.id) || matchedPairs.includes(card.id)}
            onClick={() => handleCardClick(card.id)}
          >
            <CardFront>?</CardFront>
            <CardBack>{card.content}</CardBack>
          </Card>
        </CardContainer>
      ))}
    </BoardContainer>
  );
}

export default GameBoard;