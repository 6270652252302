import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const GameContainer = styled.div`
  text-align: center;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.6);  // Semi-transparent overlay
  min-height: calc(100vh - 80px);  // Full height minus header
`;

const WelcomeText = styled.h1`
  font-family: 'Comic Sans MS', cursive;
  font-size: 3rem;
  color: white;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.4);
  margin-bottom: 2rem;
`;

const DifficultyButton = styled.button`
  background-color: rgba(76, 175, 80, 0.9);
  color: white;
  font-size: 1.5rem;
  padding: 1rem 2rem;
  margin: 1rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);

  &:hover {
    transform: scale(1.05);
    background-color: rgba(69, 160, 73, 0.9);
  }
`;

function MathMatch() {
  const navigate = useNavigate();

  const handleDifficultySelect = (difficulty) => {
    navigate(`/math-match/${difficulty}`);
  };

  return (
    <GameContainer>
      <WelcomeText>Welcome to Math Match!</WelcomeText>
      <div>
        <DifficultyButton onClick={() => handleDifficultySelect('easy')}>
          Easy
        </DifficultyButton>
        <DifficultyButton onClick={() => handleDifficultySelect('medium')}>
          Medium
        </DifficultyButton>
        <DifficultyButton onClick={() => handleDifficultySelect('hard')}>
          Hard
        </DifficultyButton>
      </div>
    </GameContainer>
  );
}

export default MathMatch; 